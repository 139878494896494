/* eslint-disable max-lines */
import { productsVariants } from '@/utils/productsVariants'
import { groupAndCheckActive } from '@/utils/helpers'
import { isError, isError404 } from '@/utils/api'

import {
  getProduct,
  getProducts,
  getProductsWepod,
  editProduct,
  activeVariant,
  activeWepod,
  activeWepodGroup,
  addPrintingArea,
  editPrintingArea,
  deletePrintingArea,
  deletePrintingAreaWepod,
  getPrintingTypes,
  getPrintingTypesWepod,
  activeAcromatic,
  addSubattributes,
  updateProductDimensions,
  getGenericProducts,
  editProductImage,
  setVariantTemplate,
  setVariantPrintingColor,
  variantPrintConfigAdd,
  variantPrintConfigSave,
  variantPrintConfigDel,
  setVariantPrintingColorGroup,
  updateProductPrintingTypes,
  updateProductPrintingTypesWepod,
  downloadSetupFile
} from '@/services/ProductService'
import {
  getProductFamilies,
  getProductFamiliesWepod,
  getFamiliesFilter,
  getSubfamilies
} from '@/services/ProductFamilyService'
import {
  uploadImage,
  uploadGenericProduct,
  setAsMain,
  deleteImage,
  deleteImageWepod,
  setImageWepodEndpoint
} from '@/services/ImageService'
import { uploadImageAWS } from '@/services/s3Service'

import { tabNamePrefix, printingAreaActions, minimumAreasQuantity } from '@/constants/productPrintingAreas'

const loadingProperties = {
  true: 'loadingFeaturedOrPublished',
  false: 'loading'
}

const CONTENT_TYPE = 'Content-Type'
const ID_FUNC = x => x

const resetValues = {
  default: null,
  error: '',
  errorDeleteImage: null,
  errorPrintingAreas: null,
  errorSetImageAsMain: null,
  errorSetImageWepod: null,
  errorUploadImage: null,
  errorVariants: '',
  success: null,
  successDeleteImage: {},
  successPrintingAreas: '',
  successUploadImage: {},
  successSetImageAsMain: {},
  successSetImageWepod: {}
}

export default {
  namespaced: true,

  state: {
    currentPage: 1,
    products: [],
    genericProducts: [],
    sort: {},
    totalRows: 0,
    productDetail: null,
    families: [],
    familiesWepod: [],
    familiesFilter: [],
    subfamilies: [],
    printingAreasTabs: [],
    printingAreasSelectedTab: '',
    printingAreasTabsWepod: [],
    printingAreasWepodSelectedTab: '',
    printingTypes: [],
    printingTypesWepod: [],
    loading: false,
    loadingDeleteImage: false,
    loadingFeaturedOrPublished: false,
    loadingPrintingAreas: false,
    loadingSetImageAsMain: false,
    loadingSetImageWepod: false,
    loadingUploadImage: false,
    loadingVariant: false,
    loadingSubattributes: false,
    loadingFamilies: false,
    error: null,
    errorDeleteImage: null,
    errorPrintingAreas: null,
    errorSetImageAsMain: null,
    errorSetImageWepod: null,
    errorUploadImage: null,
    errorVariants: null,
    success: false,
    successDeleteImage: {},
    successPrintingAreas: '',
    successSetImageAsMain: {},
    successSetImageWepod: {},
    successUploadImage: {},
    expressBuyAvailable: false,
    variantConfigResponse: {},
    mainPrintingArea: null
  },

  mutations: {
    SET_STATE_VALUE(state, { field, value }) {
      state[field] = value
    },
    SET_PRODUCTS(state, { products, count, sort }) {
      state.products = products
      state.totalRows = count
      state.sort = sort
    },
    SET_VARIANT_ACTIVE(state, variantUpdated) {
      const productDetails = { ...state.productDetail }
      const variants = productDetails.products.map(variant =>
        variant.id === variantUpdated.id ? { ...variant, active: variantUpdated.active } : variant
      )
      productDetails.products = variants
      state.productDetail = productDetails
    },
    SET_VARIANT_ACHROMATIC(state, variantUpdated) {
      const productDetails = { ...state.productDetail }
      const variants = productDetails.products.map(variant =>
        variant.id === variantUpdated.id ? { ...variant, achromatic: variantUpdated.achromatic } : variant
      )
      productDetails.products = variants
      state.productDetail = productDetails
    }
  },

  actions: {
    updateVariantImage(ctx, { imageId, areaId, wepod }) {
      return new Promise(resolve => {
        editProductImage(imageId, areaId, wepod).then(() => {
          resolve()
        })
      })
    },
    setExpressBuyAvailable({ commit }, value) {
      commit('SET_STATE_VALUE', { field: 'expressBuyAvailable', value })
    },
    async getFamilies({ commit }) {
      commit('SET_STATE_VALUE', { field: 'loadingFamilies', value: true })
      const response = await getProductFamilies().catch(error => error.response)
      if (isError(response.status)) {
        commit('SET_STATE_VALUE', { field: 'families', value: [] })
      } else {
        const { families } = response.data
        commit('SET_STATE_VALUE', { field: 'families', value: families })
      }
      commit('SET_STATE_VALUE', { field: 'loadingFamilies', value: false })
    },
    async getFamiliesWepod({ commit }) {
      commit('SET_STATE_VALUE', { field: 'loadingFamilies', value: true })
      const response = await getProductFamiliesWepod().catch(error => error.response)
      if (isError(response.status)) {
        commit('SET_STATE_VALUE', { field: 'familiesWepod', value: [] })
      } else {
        const { families } = response.data
        commit('SET_STATE_VALUE', { field: 'familiesWepod', value: families })
      }
      commit('SET_STATE_VALUE', { field: 'loadingFamilies', value: false })
    },
    getSubfamilies({ commit }) {
      getSubfamilies().then(
        response =>
          commit('SET_STATE_VALUE', {
            field: 'subfamilies',
            value: response.data
          }),
        () => commit('SET_STATE_VALUE', { field: 'families', value: [] })
      )
    },
    getFamiliesFilter({ commit }) {
      getFamiliesFilter().then(
        response =>
          commit('SET_STATE_VALUE', {
            field: 'familiesFilter',
            value: response.data.families
          }),
        () => commit('SET_STATE_VALUE', { field: 'familiesFilter', value: [] })
      )
    },
    async getProducts({ commit, dispatch, state }, { page = 1, filters = {}, sort } = {}) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      commit('SET_STATE_VALUE', { field: 'currentPage', value: page })
      const { families, familiesFilter, subfamilies } = state
      if (families && !families.length) await dispatch('getFamilies')
      if (familiesFilter && !familiesFilter.length) await dispatch('getFamiliesFilter')
      if (subfamilies && !subfamilies.length) await dispatch('getSubfamilies')
      const params = { page, ...filters, sort }
      const response = await getProducts(params)
      if (isError(response.status)) {
        const { status, statusText } = response
        commit('SET_STATE_VALUE', {
          field: 'error',
          value: { status, statusText }
        })
        commit('SET_PRODUCTS', { products: [], count: 0, sort: {} })
      } else {
        const { genericProducts, count } = response.data
        commit('SET_STATE_VALUE', { field: 'error', value: null })
        commit('SET_PRODUCTS', { products: genericProducts, count, sort })
      }
      commit('SET_STATE_VALUE', { field: 'loading', value: false })
    },
    async getProductsWepod({ commit, dispatch, state }, { page = 1, filters = {}, sort } = {}) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      commit('SET_STATE_VALUE', { field: 'currentPage', value: page })
      const { families, familiesFilter, subfamilies } = state
      if (families && !families.length) await dispatch('getFamiliesWepod')
      if (familiesFilter && !familiesFilter.length) await dispatch('getFamiliesFilter')
      if (subfamilies && !subfamilies.length) await dispatch('getSubfamilies')
      const params = { page, ...filters, sort }
      const response = await getProductsWepod(params)
      if (isError(response.status)) {
        const { status, statusText } = response
        commit('SET_STATE_VALUE', {
          field: 'error',
          value: { status, statusText }
        })
        commit('SET_PRODUCTS', { products: [], count: 0, sort: {} })
      } else {
        const { genericProducts, count } = response.data
        commit('SET_STATE_VALUE', { field: 'error', value: null })
        commit('SET_PRODUCTS', { products: genericProducts, count, sort })
      }
      commit('SET_STATE_VALUE', { field: 'loading', value: false })
    },
    /* eslint-disable max-statements */
    /* eslint-disable complexity */
    async getProduct({ commit, dispatch, state }, { id, wepod = false }) {
      const { printingTypes } = state
      const { printingTypesWepod } = state
      if (printingTypes && !printingTypes.length) dispatch('getPrintingTypes')
      if (printingTypesWepod && !printingTypesWepod.length) dispatch('getPrintingTypesWepod')
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      const response = await getProduct(id)
      if (!response || isError(response.status)) {
        const { status, statusText } = response
        commit('SET_STATE_VALUE', {
          field: 'error',
          value: { status, statusText }
        })
        commit('SET_STATE_VALUE', { field: 'productDetail', value: null })
      } else {
        const { genericProduct } = response.data
        commit('SET_STATE_VALUE', { field: 'error', value: null })
        if (genericProduct.buyBySize && wepod) {
          genericProduct.products = productsVariants(genericProduct.products)
        }

        commit('SET_STATE_VALUE', {
          field: 'productDetail',
          value: genericProduct
        })

        let printingAreasTabs = []
        if (genericProduct.printingAreas && genericProduct.printingAreas.length) {
          printingAreasTabs = genericProduct.printingAreas.map((printingArea, index) => {
            const tabName = `${tabNamePrefix.default}${genericProduct.id}-${index}`
            const tabLabel = printingArea.name || (index + 1).toString()
            return { id: printingArea.id, tabName, tabLabel }
          })
        } else {
          printingAreasTabs = [...Array(minimumAreasQuantity).keys()].map(areaIndex => ({
            tabName: `${tabNamePrefix.new}${genericProduct.id}-${areaIndex}-${Date.now()}`,
            tabLabel: (areaIndex + 1).toString()
          }))
        }
        const printingAreasSelectedTab = printingAreasTabs[0].tabName

        let printingAreasTabsWepod = []
        if (genericProduct.printingAreasWepod && genericProduct.printingAreasWepod.length) {
          printingAreasTabsWepod = genericProduct.printingAreasWepod.map((printingArea, index) => {
            const tabName = `${tabNamePrefix.default}${genericProduct.id}-${index}`
            const tabLabel = printingArea.name || (index + 1).toString()
            return { id: printingArea.id, tabName, tabLabel }
          })
        } else {
          printingAreasTabsWepod = [...Array(minimumAreasQuantity).keys()].map(areaIndex => ({
            tabName: `${tabNamePrefix.new}${genericProduct.id}-${areaIndex}-${Date.now()}`,
            tabLabel: (areaIndex + 1).toString()
          }))
        }
        const printingAreasWepodSelectedTab = printingAreasTabsWepod[0].tabName

        commit('SET_STATE_VALUE', {
          field: 'printingAreasTabs',
          value: printingAreasTabs
        })
        commit('SET_STATE_VALUE', {
          field: 'printingAreasSelectedTab',
          value: printingAreasSelectedTab
        })
        commit('SET_STATE_VALUE', {
          field: 'printingAreasTabsWepod',
          value: printingAreasTabsWepod
        })
        commit('SET_STATE_VALUE', {
          field: 'printingAreasWepodSelectedTab',
          value: printingAreasWepodSelectedTab
        })
        if (genericProduct.printingAreas && genericProduct.printingAreas.length > 0) {
          const area = genericProduct.printingAreas.find(area => area.main)
          const tabName = printingAreasTabs.find(tab => tab.id === area.id).tabName
          commit('SET_STATE_VALUE', {
            field: 'mainPrintingArea',
            value: tabName
          })
        }
      }
      commit('SET_STATE_VALUE', { field: 'loading', value: false })
    },
    uploadDatasheetFile({ commit }, file) {
      const fileNameSplit = file.name.split('.')
      const imageData = {
        extension: fileNameSplit.pop(),
        file_name: fileNameSplit.join(),
        path: '/productDatasheet'
      }
      return uploadImage(imageData).then(response => {
        const signedUrl = decodeURIComponent(response.data.signed_url)
        const contentType = new URL(signedUrl).searchParams.get(CONTENT_TYPE)
        return uploadImageAWS(signedUrl, file, { headers: { [CONTENT_TYPE]: contentType } }).then(
          () => signedUrl.split('?')[0],
          ({ response: responseAws }) => {
            const { status, statusText } = responseAws
            commit('SET_STATE_VALUE', {
              field: 'error',
              value: { status, statusText }
            })
          }
        )
      })
    },
    async editProduct({ commit, dispatch }, { id, fields, changedFeaturedOrPublished, noReload }) {
      const loadingProperty = loadingProperties[!!changedFeaturedOrPublished]
      commit('SET_STATE_VALUE', { field: loadingProperty, value: true })
      const { fileDatasheet } = fields
      let datashetUrl = undefined

      if (fileDatasheet) {
        datashetUrl =
          fileDatasheet instanceof File
            ? await dispatch('uploadDatasheetFile', fileDatasheet)
            : fileDatasheet.url
      }
      if (datashetUrl) fields = { ...fields, fileDatasheet: datashetUrl }

      await editProduct(id, fields).then(
        () =>
          getProduct(id).then(response => {
            const { genericProduct } = response.data
            commit('SET_STATE_VALUE', { field: 'error', value: null })
            if (!noReload) {
              commit('SET_STATE_VALUE', {
                field: 'productDetail',
                value: genericProduct
              })
            }

            commit('SET_STATE_VALUE', {
              field: 'success',
              value: {
                ok: true,
                changedFeaturedOrPublished: !!changedFeaturedOrPublished
              }
            })
            commit('SET_STATE_VALUE', { field: loadingProperty, value: false })
          }),
        ({ request }) => {
          const { response, status, statusText } = request
          const parseResponse = isError404(status) ? {} : JSON.parse(response)
          // eslint-disable-next-line camelcase
          const { message, internal_code } = parseResponse
          commit('SET_STATE_VALUE', {
            field: 'error',
            value: {
              status,
              statusText,
              message,
              internalCode: internal_code,
              changedFeaturedOrPublished: !!changedFeaturedOrPublished
            }
          })
          commit('SET_STATE_VALUE', { field: 'success', value: null })
          commit('SET_STATE_VALUE', { field: loadingProperty, value: false })
        }
      )
    },
    async editProductWepod({ commit }, { id, fields, changedFeaturedOrPublished }) {
      const loadingProperty = loadingProperties[!!changedFeaturedOrPublished]
      commit('SET_STATE_VALUE', { field: loadingProperty, value: true })

      await editProduct(id, fields).then(
        () => {
          commit('SET_STATE_VALUE', {
            field: 'success',
            value: {
              ok: true,
              changedFeaturedOrPublished: !!changedFeaturedOrPublished
            }
          })
          commit('SET_STATE_VALUE', { field: loadingProperty, value: false })
        },
        ({ request }) => {
          const { response, status, statusText } = request
          const parseResponse = isError404(status) ? {} : JSON.parse(response)
          // eslint-disable-next-line camelcase
          const { message, internal_code } = parseResponse
          commit('SET_STATE_VALUE', {
            field: 'error',
            value: {
              status,
              statusText,
              message,
              internalCode: internal_code,
              changedFeaturedOrPublished: !!changedFeaturedOrPublished
            }
          })
          commit('SET_STATE_VALUE', { field: 'success', value: null })
          commit('SET_STATE_VALUE', { field: loadingProperty, value: false })
        }
      )
    },
    activeVariant({ commit }, { id, onFinish }) {
      commit('SET_STATE_VALUE', { field: 'loadingVariant', value: true })
      activeVariant(id).then(
        response => {
          commit('SET_VARIANT_ACTIVE', response.data && response.data.product)
          commit('SET_STATE_VALUE', { field: 'loadingVariant', value: false })
          onFinish(true)
        },
        error => {
          commit('SET_STATE_VALUE', { field: 'errorVariants', value: error })
          commit('SET_STATE_VALUE', { field: 'loadingVariant', value: false })
          onFinish()
        }
      )
    },
    activeAcromatic({ commit }, { id, onFinish }) {
      commit('SET_STATE_VALUE', { field: 'loadingVariant', value: true })
      activeAcromatic(id).then(
        response => {
          commit('SET_VARIANT_ACHROMATIC', response.data && response.data.product)
          commit('SET_STATE_VALUE', { field: 'loadingVariant', value: false })
          onFinish(true)
        },
        error => {
          commit('SET_STATE_VALUE', { field: 'errorVariants', value: error })
          commit('SET_STATE_VALUE', { field: 'loadingVariant', value: false })
          onFinish()
        }
      )
    },
    activeWepod({ commit, dispatch }, { id, onFinish, productId }) {
      commit('SET_STATE_VALUE', { field: 'loadingVariant', value: true })
      activeWepod(id).then(
        async () => {
          await dispatch('getProduct', { id: productId, wepod: true })
          commit('SET_STATE_VALUE', { field: 'loadingVariant', value: false })
          onFinish(true)
        },
        error => {
          commit('SET_STATE_VALUE', { field: 'errorVariants', value: error })
          commit('SET_STATE_VALUE', { field: 'loadingVariant', value: false })
          onFinish()
        }
      )
    },
    activeWepodGroup({ commit, dispatch }, { color, onFinish, genericProductId }) {
      commit('SET_STATE_VALUE', { field: 'loadingVariant', value: true })
      activeWepodGroup(genericProductId, color).then(
        async () => {
          await dispatch('getProduct', { id: genericProductId, wepod: true })
          commit('SET_STATE_VALUE', { field: 'loadingVariant', value: false })
          onFinish(true)
        },
        error => {
          commit('SET_STATE_VALUE', { field: 'errorVariants', value: error })
          commit('SET_STATE_VALUE', { field: 'loadingVariant', value: false })
          onFinish()
        }
      )
    },
    setImageAsMain({ commit }, id) {
      commit('SET_STATE_VALUE', {
        field: 'loadingSetImageAsMain',
        value: true
      })
      setAsMain(id).then(
        () => {
          commit('SET_STATE_VALUE', {
            field: 'successSetImageAsMain',
            value: { ok: true, imageId: id }
          })
          commit('SET_STATE_VALUE', {
            field: 'loadingSetImageAsMain',
            value: false
          })
        },
        ({ response }) => {
          commit('SET_STATE_VALUE', {
            field: 'errorSetImageAsMain',
            value: response.data
          })
          commit('SET_STATE_VALUE', {
            field: 'loadingSetImageAsMain',
            value: false
          })
        }
      )
    },
    setImageWepod({ commit }, { id, isCover, isChecked }) {
      if (isCover) {
        commit('SET_STATE_VALUE', {
          field: 'loadingSetImageWepod',
          value: true
        })
      }
      setImageWepodEndpoint(id, isCover, isChecked).then(
        () => {
          commit('SET_STATE_VALUE', {
            field: 'successSetImageWepod',
            value: { ok: true, imageId: id, isCover, isChecked }
          })
          commit('SET_STATE_VALUE', {
            field: 'loadingSetImageWepod',
            value: false
          })
        },
        ({ response }) => {
          commit('SET_STATE_VALUE', {
            field: 'errorSetImageWepod',
            value: response.data
          })
          commit('SET_STATE_VALUE', {
            field: 'loadingSetImageWepod',
            value: false
          })
        }
      )
    },
    addImage(
      { commit, dispatch },
      { imageData, file, genericProductId, productId, main, onUploadProgress, wepod }
    ) {
      /* eslint-disable */
      return new Promise(async (resolve, reject) => {
        commit("SET_STATE_VALUE", { field: "loadingUploadImage", value: true });
        const uploadImageVariant = !!productId;
        const {
          data: { signed_url: signedUrl }
        } = await uploadImage(imageData);
        await uploadImageAWS(signedUrl, file.raw || file, { onUploadProgress });

        try {
          const {
            data: { image }
          } = await uploadGenericProduct({
            generic_product_id: genericProductId,
            product_id: productId,
            image_url: signedUrl.split("?")[0],
            main,
            wepod
          });
          if(wepod){
            dispatch("getProduct", { id: genericProductId, wepod: true });
          }else{
            dispatch("updateProduct", genericProductId);
          }

          commit("SET_STATE_VALUE", {
            field: "successUploadImage",
            value: { ok: true, uploadImageVariant }
          });
          commit("SET_STATE_VALUE", {
            field: "loadingUploadImage",
            value: false
          });
          resolve(image);
        } catch ({ response }) {
          commit("SET_STATE_VALUE", {
            field: "errorUploadImage",
            value: { ...response.data, file, uploadImageVariant }
          });
          commit("SET_STATE_VALUE", {
            field: "loadingUploadImage",
            value: false
          });
          commit("SET_STATE_VALUE", { field: "successUploadImage", value: {} });
          reject(response);
        }
      })
    },
    deleteImage({ commit, dispatch }, { id, genericProductId }) {
      commit("SET_STATE_VALUE", { field: "loadingDeleteImage", value: true });
      deleteImage(id).then(
        () => {
          dispatch("updateProduct", genericProductId);
          commit("SET_STATE_VALUE", {
            field: "successDeleteImage",
            value: { ok: true, imageId: id }
          });
          commit("SET_STATE_VALUE", {
            field: "loadingDeleteImage",
            value: false
          });
        },
        () => {
          commit("SET_STATE_VALUE", { field: "errorDeleteImage", value: true });
          commit("SET_STATE_VALUE", {
            field: "loadingDeleteImage",
            value: false
          });
        }
      );
    },
    deleteImageWepod({ commit, dispatch }, { id, genericProductId }) {
      commit("SET_STATE_VALUE", { field: "loadingDeleteImage", value: true });
      deleteImageWepod(id).then(
        () => {
          dispatch("getProduct", { id: genericProductId, wepod: true });
          commit("SET_STATE_VALUE", {
            field: "successDeleteImage",
            value: { ok: true, imageId: id }
          });
          commit("SET_STATE_VALUE", {
            field: "loadingDeleteImage",
            value: false
          });
        },
        () => {
          commit("SET_STATE_VALUE", { field: "errorDeleteImage", value: true });
          commit("SET_STATE_VALUE", {
            field: "loadingDeleteImage",
            value: false
          });
        }
      );
    },
    updateProduct({ commit }, genericProductId) {
      getProduct(genericProductId).then(({ data }) =>
        commit("SET_STATE_VALUE", {
          field: "productDetail",
          value: data.genericProduct
        })
      );
    },
    updateProductDimensions(ctx, body) {
      return new Promise((resolve, reject) => {
        updateProductDimensions(body)
          .then(res => {
            resolve(res);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    addPrintingArea({ commit, dispatch, state }, { idProduct, fields, tabName }) {
      commit("SET_STATE_VALUE", { field: "loadingPrintingAreas", value: true });
      addPrintingArea(idProduct, fields).then(
        ({ printingArea }) =>{
          dispatch("updatePrintingAreasTabs", {
            idArea: printingArea.id,
            tabName
          })
          if (fields.wepod) {
            commit('SET_STATE_VALUE', {
              field: 'productDetail',
              value: {
                ...state.productDetail,
                printingAreasWepod: [ ...state.productDetail.printingAreasWepod, printingArea ]
              }
            })
          }
        },
        ({ response }) => {
          commit("SET_STATE_VALUE", {
            field: "errorPrintingAreas",
            value: { ...response.data, action: printingAreaActions.add }
          });
          commit("SET_STATE_VALUE", {
            field: "loadingPrintingAreas",
            value: false
          });
        }
      );
    },
    async editPrintingArea({ commit, state }, { idProduct, idArea, fields, wepod = false }) {
      commit("SET_STATE_VALUE", { field: "loadingPrintingAreas", value: true });
      editPrintingArea(idProduct, idArea, fields, wepod).then(
        async () => {
          if(!wepod) {
            getProduct(idProduct).then(response => {
              const { genericProduct } = response.data
              commit('SET_STATE_VALUE', {
                field: 'productDetail',
                value: genericProduct
              })
            })
          } else {
            getProduct(idProduct).then(response => {
              const { genericProduct } = response.data
              commit('SET_STATE_VALUE', {
                field: 'productDetail',
                value: {
                  ...state.productDetail,
                  printingAreasWepod: genericProduct.printingAreasWepod
                }
              })
              commit('SET_STATE_VALUE', {
                field: 'success',
                value: {
                  ok: true
                }
              })
            })
          }
          
          commit("SET_STATE_VALUE", {
            field: "errorPrintingAreas",
            value: null
          });
          commit("SET_STATE_VALUE", {
            field: "successPrintingAreas",
            value: printingAreaActions.edit
          });
          commit("SET_STATE_VALUE", {
            field: "loadingPrintingAreas",
            value: false
          });
        },
        ({ response }) => {
          commit("SET_STATE_VALUE", {
            field: "errorPrintingAreas",
            value: { ...response.data, action: printingAreaActions.edit }
          });
          commit("SET_STATE_VALUE", {
            field: "loadingPrintingAreas",
            value: false
          });
        }
      );
    },
    deletePrintingArea({ commit, dispatch, state }, tabName) {
      commit("SET_STATE_VALUE", { field: "loadingPrintingAreas", value: true });
      const { printingAreasTabs } = state;
      if (printingAreasTabs.length > minimumAreasQuantity) {
        const deleteTab = printingAreasTabs.find(
          tab => tab.tabName === tabName
        );
        if (deleteTab.id) {
          deletePrintingArea(deleteTab.id).then(
            () => dispatch("deleteAreaTab", tabName),
            ({ response }) => {
              commit("SET_STATE_VALUE", {
                field: "errorPrintingAreas",
                value: { ...response.data, action: printingAreaActions.delete }
              });
              commit("SET_STATE_VALUE", {
                field: "loadingPrintingAreas",
                value: false
              });
            }
          );
        } else {
          dispatch("deleteAreaTabWepod", tabName);
        }
      }
    },
    deletePrintingAreaWepod({ commit, dispatch, state }, tabName) {
      commit("SET_STATE_VALUE", { field: "loadingPrintingAreas", value: true });
      const { printingAreasTabsWepod } = state;
      if (printingAreasTabsWepod.length > minimumAreasQuantity) {
        const deleteTab = printingAreasTabsWepod.find(
          tab => tab.tabName === tabName
        );
        if (deleteTab.id) {
          deletePrintingAreaWepod(deleteTab.id).then(
            () => {
              dispatch("deleteAreaTabWepod", tabName)
              commit('SET_STATE_VALUE', {
                field: 'productDetail',
                value: {
                  ...state.productDetail,
                  printingAreasWepod: state.productDetail.printingAreasWepod.filter(area => area.id !== deleteTab.id)
                }
              }) 
            },
            ({ response }) => {
              commit("SET_STATE_VALUE", {
                field: "errorPrintingAreas",
                value: { ...response.data, action: printingAreaActions.delete }
              });
              commit("SET_STATE_VALUE", {
                field: "loadingPrintingAreas",
                value: false
              });
            }
          );
        } else {
          dispatch("deleteAreaTabWepod", tabName);
        }
      }
    },
    addAreaTab({ commit, state }, newArea) {
      const { printingAreasTabs } = state;
      const newPrintingAreasTabs = Object.assign([], printingAreasTabs);
      newPrintingAreasTabs.push(newArea);
      commit("SET_STATE_VALUE", {
        field: "printingAreasTabs",
        value: newPrintingAreasTabs
      });
      commit("SET_STATE_VALUE", {
        field: "printingAreasSelectedTab",
        value: newArea.tabName
      });
    },
    addAreaTabWepod({ commit, state }, newArea) {
      const { printingAreasTabsWepod } = state;
      const newPrintingAreasTabsWepod = Object.assign([], printingAreasTabsWepod);
      newPrintingAreasTabsWepod.push(newArea);
      commit("SET_STATE_VALUE", {
        field: "printingAreasTabsWepod",
        value: newPrintingAreasTabsWepod
      });
      commit("SET_STATE_VALUE", {
        field: "printingAreasWepodSelectedTab",
        value: newArea.tabName
      });
    },
    updatePrintingAreasTabs({ commit, state }, { idArea, tabName }) {
      const { printingAreasTabs } = state;
      const newPrintingAreasTabs = printingAreasTabs.map(tab =>
        tab.tabName === tabName ? { ...tab, id: idArea } : tab
      );
      commit("SET_STATE_VALUE", {
        field: "printingAreasTabs",
        value: newPrintingAreasTabs
      });
      commit("SET_STATE_VALUE", { field: "errorPrintingAreas", value: null });
      commit("SET_STATE_VALUE", {
        field: "successPrintingAreas",
        value: printingAreaActions.add
      });
      commit("SET_STATE_VALUE", {
        field: "loadingPrintingAreas",
        value: false
      });
    },
    deleteAreaTab({ commit, state }, tabName) {
      const { printingAreasTabs } = state;
      const newPrintingAreasTabs = printingAreasTabs.filter(
        tab => tab.tabName !== tabName
      );
      commit("SET_STATE_VALUE", {
        field: "printingAreasTabs",
        value: newPrintingAreasTabs
      });
      commit("SET_STATE_VALUE", {
        field: "printingAreasSelectedTab",
        value:
          newPrintingAreasTabs[
            newPrintingAreasTabs.length - minimumAreasQuantity
          ].tabName
      });
      commit("SET_STATE_VALUE", { field: "errorPrintingAreas", value: null });
      commit("SET_STATE_VALUE", {
        field: "successPrintingAreas",
        value: printingAreaActions.delete
      });
      commit("SET_STATE_VALUE", {
        field: "loadingPrintingAreas",
        value: false
      });
    },
    deleteAreaTabWepod({ commit, state }, tabName) {
      const { printingAreasTabsWepod } = state;
      const newPrintingAreasTabsWepod = printingAreasTabsWepod.filter(
        tab => tab.tabName !== tabName
      );
      commit("SET_STATE_VALUE", {
        field: "printingAreasTabsWepod",
        value: newPrintingAreasTabsWepod
      });
      commit("SET_STATE_VALUE", {
        field: "printingAreasSelectedTabWepod",
        value:
          newPrintingAreasTabsWepod[
            newPrintingAreasTabsWepod.length - minimumAreasQuantity
          ].tabName
      });
      commit("SET_STATE_VALUE", { field: "errorPrintingAreas", value: null });
      commit("SET_STATE_VALUE", {
        field: "successPrintingAreas",
        value: printingAreaActions.delete
      });
      commit("SET_STATE_VALUE", {
        field: "loadingPrintingAreas",
        value: false
      });
    },
    changeNameAreaTab({ commit, state }, newTabLabel) {
      const { printingAreasSelectedTab, printingAreasTabs } = state;
      const newPrintingAreasTabs = printingAreasTabs.map(area => {
        const areaReturn = { ...area };
        if (areaReturn.tabName === printingAreasSelectedTab) {
          areaReturn.tabLabel = newTabLabel;
        }
        return areaReturn;
      });
      commit("SET_STATE_VALUE", {
        field: "printingAreasTabs",
        value: newPrintingAreasTabs
      });
    },
    changeNameAreaTabWepod({ commit, state }, newTabLabel) {
      const { printingAreasWepodSelectedTab, printingAreasTabsWepod } = state;
      const newPrintingAreasTabsWepod = printingAreasTabsWepod.map(area => {
        const areaReturn = { ...area };
        if (areaReturn.tabName === printingAreasWepodSelectedTab) {
          areaReturn.tabLabel = newTabLabel;
        }
        return areaReturn;
      });
      commit("SET_STATE_VALUE", {
        field: "printingAreasTabsWepod",
        value: newPrintingAreasTabsWepod
      });
    },
    getPrintingTypes({ commit }) {
      getPrintingTypes().then(({ printingTypes }) =>{
        const groupedArray=groupAndCheckActive(printingTypes)

        commit("SET_STATE_VALUE", {
          field: "printingTypes",
          value: groupedArray
        })
      }
      );
    },
    getPrintingTypesWepod({ commit }) {
      getPrintingTypesWepod().then(({ printingTypes }) =>{
        const groupedArray=groupAndCheckActive(printingTypes)
        commit("SET_STATE_VALUE", {
          field: "printingTypesWepod",
          value: groupedArray
        })
      }

      );
    },
    selectAreaTab({ commit }, selectedTabName) {
      commit("SET_STATE_VALUE", {
        field: "printingAreasSelectedTab",
        value: selectedTabName
      });
    },
    selectAreaTabWepod({ commit }, selectedTabName) {
      commit("SET_STATE_VALUE", {
        field: "printingAreasWepodSelectedTab",
        value: selectedTabName
      });
    },
    resetValue({ commit }, field) {
      commit("SET_STATE_VALUE", {
        field,
        value: resetValues[field] || resetValues.default
      });
    },
    resetVariantsError({ commit }) {
      commit("SET_STATE_VALUE", { field: "errorVariants", value: "" });
    },
    resetPrintingAreasError({ commit }) {
      commit("SET_STATE_VALUE", { field: "errorPrintingAreas", value: null });
    },
    resetPrintingAreasSuccess({ commit }) {
      commit("SET_STATE_VALUE", { field: "successPrintingAreas", value: "" });
    },
    addSubattributes({ commit }, { id, subattributes, onFinish = ID_FUNC }) {
      commit("SET_STATE_VALUE", { field: "loadingSubattributes", value: true });
      addSubattributes(id, subattributes)
        .then(
          () => onFinish(true, "addSubattributes"),
          () => onFinish(false, "addSubattributes")
        )
        .finally(() =>
          commit("SET_STATE_VALUE", {
            field: "loadingSubattributes",
            value: false
          })
        );
    },
    async getGenericProducts({ commit }, { limit }) {
      const response = await getGenericProducts({ limit })
      if (response) {
        commit('SET_STATE_VALUE', { field: 'genericProducts', value: response.data.genericProducts })
      } else {
        commit('SET_STATE_VALUE', { field: 'genericProducts', value: undefined })
      }
    },
    async setVariantTemplate({ commit },{id,templateId,data}) {
      await setVariantTemplate(id,templateId,data)
      commit('SET_STATE_VALUE', {
        field: 'success',
        value: {
          ok: true
        }
      })
    },
    async setVariantPrintingColor({ commit }, body) {
      await setVariantPrintingColor(body)
      commit('SET_STATE_VALUE', {
        field: 'success',
        value: {
          ok: true
        }
      })
    },
    async variantPrintConfigAdd({ commit }, {variantId, body, onFinish = ID_FUNC}) {
      const res = await variantPrintConfigAdd(variantId, body)
      return res
    },
    async variantPrintConfigSave({ commit }, body) {
      const res = await variantPrintConfigSave(body)
      return res
    },
    async variantPrintConfigDel({ commit }, body) {
      const res = await variantPrintConfigDel(body)
      return res
    },
    async setVariantPrintingColorGroup({ commit }, body) {
      await setVariantPrintingColorGroup(body)
      commit('SET_STATE_VALUE', {
        field: 'success',
        value: {
          ok: true
        }
      })
    },
    updateProductPrintingTypes({commit}, body) {
      updateProductPrintingTypes(body)
      .then(res => {
        if (!res || isError(res.status)) {
          const { status, statusText } = res
          commit('SET_STATE_VALUE', {
            field: 'error',
            value: { status, statusText }
          })
          commit('SET_STATE_VALUE', { field: 'productDetail', value: null })
        } else {
        getProduct(body[0].generic_product_id).then(response => {
          const { genericProduct } = response.data
            commit('SET_STATE_VALUE', {
              field: 'productDetail',
              value: genericProduct
            })
            commit('SET_STATE_VALUE', {
              field: 'success',
              value: {
                ok: true
              }
            })
          })
        }
      });
    },
    async updateProductPrintingTypesWepod({commit,dispatch}, {printingTypes,genericProductId}) {

      const res= await updateProductPrintingTypesWepod(printingTypes)
        if (!res || isError(res.status)) {
          const { status, statusText } = res
          commit('SET_STATE_VALUE', {
            field: 'error',
            value: { status, statusText }
          })
          commit('SET_STATE_VALUE', { field: 'productDetail', value: null })
        } else {
          await dispatch('getProduct', { id: genericProductId, wepod: true })
          commit('SET_STATE_VALUE', {
            field: 'success',
            value: {
              ok: true
            }
          })
        }

    },
    setPrintingAreaAsMain({ commit }, id) {
      commit('SET_STATE_VALUE', {
        field: 'mainPrintingArea',
        value: id
      })
    },
    downloadSetupFile({ commit }, { filename }) {
      commit('SET_STATE_VALUE', { field: 'loading', value: true })
      // eslint-disable-next-line no-unused-vars
      downloadSetupFile(filename).then(response => {
        commit('SET_STATE_VALUE', { field: 'loading', value: false })
      })
    }
  }
};
